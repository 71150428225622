<template>
    <b-overlay :show="showLoader">
        <b-card>
            <b-button variant="primary" @click="$router.push({name: 'add-service'})" v-if="$hasPermissions($permissions.EditService)">Dodaj storitev</b-button>
            <b-alert class="mt-1 p-1 text-center" show variant="warning" v-if="hasBeenDeleted">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če je novica po brisanju še vedno vidna, ponovno naložite stran.</b-alert>

            <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/es/services" :fromElastic="true">
                <template #cell(main_photo)="row">
                    <b-img fluid :src="`/api/management/v1/image/thumbnail_${row.item.main_photo}`" style="width: 100%; max-height: 70px; object-fit: contain;" alt="Image"/>
                </template>
                <template #cell(date_added)="row">
                    {{ moment(row.item.date_added).format('DD.MM.YYYY') }}
                </template>
                <template #cell(active)="row">
                    <b-badge v-if="row.item.active" variant="light-success">
                        Da
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Ne
                    </b-badge>
                </template>
                <template #cell(actions)="row" v-if="$hasPermissions($permissions.EditService)">
                    <div class="button-grid">
                        <!-- <b-button variant="primary" :href="'/storitev/' + row.item.id" target="_blank"><fa icon="newspaper"/></b-button> -->
                        <b-button v-if="row.item.usage_type === 1" variant="info"  @click="$router.push({name: 'service-coupons', params: {'service_id': row.item.id}})"><fa icon="info"/></b-button>
                        <b-button variant="warning" @click="$router.push({name: 'edit-service', params: {'service_id': row.item.id}})"><fa icon="edit"/></b-button>
                        <b-button @click="deleteService(row.item.id)" variant="danger"><fa icon="trash"/></b-button>
                    </div>
                </template>
            </Table>
        </b-card>
    </b-overlay>
</template>
<script>

    import Table from '@/views/Components/Table'
    import {BOverlay, BButton, BImg, BCard, BBadge, BAlert} from 'bootstrap-vue'
    export default {
        components: {
            Table,
            BOverlay,
            BButton,
            BImg,
            BCard,
            BBadge,
            BAlert
        },
        data() {
            return {
                showLoader: false,
                fields: [
                    { key: 'main_photo', label: 'Slika', sortable: true, class: 'text-center' },
                    { key: 'title', label: 'Naslov', sortable: true, class: 'text-center' },
                    { key: 'date_added', label: 'Datum', sortable: true, class: 'text-center', type: 'date' },
                    { key: 'active', label: 'Aktivno', sortable: true, class: 'text-center', type: 'bool' }
                ],
                hasBeenDeleted: false
            }
        },
        methods: {
            deleteService(id) {
                this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati storitev.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                }).then(value => {
                    if (value) {
                        const thisIns = this
                        thisIns.showLoader = true
                        this.$http.delete(`/api/management/v1/service/${id}`)
                            .then(() => {
                                thisIns.$printSuccess('Storitev je izbrisana')
                                thisIns.hasBeenDeleted = true
                                setTimeout(() => {
                                    thisIns.hasBeenDeleted = false
                                }, 10_000)

                                setTimeout(() => {
                                    thisIns.$refs.table.search()
                                }, 1000)
                            }).catch((error) => {
                                thisIns.$printError(error.response.data)
                            }).finally(function() {
                                thisIns.showLoader = false
                            })
                    }
                })
            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditService)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>
<style scoped>
.button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
}
</style>